<template>
  <div class="flex flex-col gap-3">
    <!-- <h4>Header</h4> -->
    <div class="flex flex-col gap-4 w-full">
      <div class="flex flex-col gap-2 w-full sm:w-2/3">
        <span>Supplier Credit Note Number</span>
        <vs-input class="w-full" v-model="formHeaderModels.cnNumber" />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Supplier Credit Note Date</span>
        <!-- <vs-input class="w-full" v-model="formHeaderModels.cnDate" /> -->
        <datepicker
          class="w-full"
          :inline="false"
          name="Debit Note Date"
          placeholder="Select Debit Note Date"
          v-model="formHeaderModels.cnDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Return Number</span>
        <vs-input class="w-full" v-model="formHeaderModels.trNumber" disabled />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Return Date</span>
        <datepicker
          class="w-full"
          :inline="false"
          name="Tax Return Date"
          placeholder="Select Tax Return Date"
          v-model="formHeaderModels.trDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Settlement Method</span>
        <!-- <vs-input class="w-full" v-model="formHeaderModels.settlementMethod" /> -->
        <multiselect
          class="selectExample"
          v-model="formHeaderModels.settlementMethod"
          :options="
            !settlementMethodOptions || settlementMethodOptions.length == 0
              ? []
              : settlementMethodOptions
          "
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder="Type to search"
          track-by="SettlementMethod"
          label="SettlementMethod"
          :disabled="false"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                {{ props.option.SettlementMethod }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Invoice Reference</span>
        <div class="flex gap-3 items-center">
          <multiselect
            class="w-1/2"
            v-model="formHeaderModels.taxInvoiceReference"
            :options="
              !taxInvoiceReferenceOptions ||
              taxInvoiceReferenceOptions.length == 0
                ? []
                : taxInvoiceReferenceOptions
            "
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder="Type to search"
            track-by="id"
            :custom-label="queryTaxInvoiceReference"
            :disabled="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  v-if="props.option.tax_return_number && props.option.value"
                  class="option__title"
                >
                  {{ props.option.tax_return_number }} -
                  {{ priceFormat(props.option.remaining) }}
                </span>
                <span
                  v-else-if="props.option.tax_return_number"
                  class="option__desc"
                >
                  {{ props.option.tax_return_number }}
                </span>
                <span v-else class="option__desc">
                  {{ props.option.label }}
                </span>
              </div>
            </template>
          </multiselect>
          <vs-input
            class="w-1/2"
            v-model="formHeaderModels.taxInvoiceReferenceCode"
            :disabled="
              this.formHeaderModels.taxInvoiceReference &&
              Object.keys(this.formHeaderModels.taxInvoiceReference).length > 0
            "
          />
        </div>
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Invoice Reference Date</span>
        <datepicker
          class="w-full"
          :inline="false"
          name="Tax Invoice Reference Date"
          placeholder="Select Tax Invoice Reference Date"
          v-model="formHeaderModels.taxInvoiceReferenceRefDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Posting Date</span>
        <!-- <vs-input class="w-full" v-model="formHeaderModels.postingDate" /> -->
        <datepicker
          class="w-full"
          :inline="false"
          name="Posting Date"
          placeholder="Select Posting Date"
          v-model="formHeaderModels.postingDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Note</span>
        <vs-input class="w-full" v-model="formHeaderModels.note" />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Attachment</span>
        <!-- <vs-input
          v-model="fileName"
          @change="onFileChange"
          class="w-full"
          type="file"
        /> -->
        <div v-if="attachment.length > 0">
          <div
            class="flex justify-start items-center gap-6"
            v-for="(tr, i) in attachment"
            :key="i"
          >
            <span>{{ tr.NameFile }}</span>
            <vx-tooltip text="Delete" style="width: fit-content">
              <vs-button
                @click="handleShowPrompt(tr)"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
              />
            </vx-tooltip>
          </div>
        </div>
        <vs-input
          v-else
          v-model="fileName"
          @change="onFileChange"
          class="w-full"
          type="file"
        />
      </div>
    </div>
    <vs-prompt
      title="Confirmation"
      color="danger"
      @cancel="handleClose"
      @close="handleClose"
      @accept="deleteFile(selectedData.ID, getAttachmentIndex(selectedData.ID))"
      :buttons-hidden="false"
      :active.sync="isPromptShow"
    >
      <div class="con-exemple-prompt">
        Debit Note
        <br />
        Are you sure to delete attachment file
        <b>{{
          this.selectedData.NameFile ? this.selectedData.NameFile : ""
        }}</b>
        ?
      </div>
    </vs-prompt>
  </div>
</template>
<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  props: {
    isSubmit: {
      type: Boolean,
      default: false,
    },
    settlementMethodOptions: {
      type: Array,
      default: () => [],
    },
    settlementMethod: {
      type: String,
      default: "",
    },
    header: {
      type: Object,
      default: () => {},
    },
    attachment: {
      type: Array,
      default: () => [],
    },
    taxInvoiceReferenceOptions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    datepicker: Datepicker,
  },
  data() {
    return {
      fileName: "",
      formHeaderModels: {
        cnNumber: "",
        cnDate: "",
        trNumber: "",
        trDate: "",
        settlementMethod: {},
        postingDate: "",
        note: "",
        taxInvoiceReference: {},
        taxInvoiceReferenceCode: "",
        taxInvoiceReferenceRefDate: "",
      },
      file: null,
      isPromptShow: false,
      selectedData: {},
      isMounted: false,
    };
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    deleteFile(id, index) {
      this.$emit("deleteAttachment", id, index);
      // this.$vs.loading();
      // var form = new FormData();
      // form.append("id", id);
      // this.$http
      //   .post("/api/v1/debit-note/delete-file", form, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((resp) => {
      //     this.$vs.loading.close();
      //     if (resp.code == 200) {
      //       this.$vs.notify({
      //         color: "success",
      //         title: "Success",
      //         text: resp.message,
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-x-circle",
      //       });
      //     } else {
      //       this.$vs.notify({
      //         color: "danger",
      //         title: "Error",
      //         text: resp.message,
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-x-circle",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.$vs.loading.close();
      //     console.log(error);
      //   });
    },
    handleClose() {
      this.isPromptShow = false;
      this.selectedData = {};
    },
    handleShowPrompt(data) {
      this.selectedData = data;
      this.isPromptShow = true;
    },
    getAttachmentIndex(id) {
      return this.attachment.findIndex((item) => item.ID == id);
    },
    queryTaxInvoiceReference({ tax_return_number, remaining, tax_name }) {
      if (!tax_return_number || !remaining || !tax_name) {
        return "";
      } else {
        return `${tax_return_number} - ${this.priceFormat(
          remaining
        )} - ${tax_name}`;
      }
    },
    onTag(tag) {
      console.log("tag", tag);
      const tagValue = tag.trim();
      const tagObj = {
        //genertae random id with number type
        id: Math.floor(Math.random() * 1000),
        code: tagValue,
        value: 0,
      };
      console.log("tagObj", tagObj);
      this.$emit("addTaxInvoiceReferenceOption", tagObj);
      this.formHeaderModels.taxInvoiceReference = tagObj;
      console.log(
        "formHeaderModels.taxInvoiceReference",
        this.formHeaderModels.taxInvoiceReference
      );
      // console.log("formHeaderModels.taxInvoiceReference", this.formHeaderModels.taxInvoiceReference);
      // this.taxInvoiceReferenceOptions.push(tagObj);
    },
    onTaxInvoiceRefSelected(value) {
      if (!value) return;
      this.$store.commit("dnReturn/setTaxInvoiceRefence", value);
    },
  },

  mounted() {
    this.formHeaderModels.cnNumber = this.header.SupplierCNNumber;
    this.formHeaderModels.cnDate =
      moment(this.header.SupplierCNDate).format("YYYY-MM-DD") === "0001-01-01"
        ? null
        : this.header.SupplierCNDate;
    this.formHeaderModels.trNumber = this.header.TaxReturnNumber;

    this.formHeaderModels.trDate =
      moment(this.header.TaxReturnDate).format("YYYY-MM-DD") === "0001-01-01"
        ? null
        : this.header.TaxReturnDate;
    this.formHeaderModels.settlementMethod = this.settlementMethodOptions.find(
      (item) => item.SettlementMethod === this.header.SettlementMethod
    );

    if (this.header.TaxInvoiceReferenceRefText !== "") {
      this.formHeaderModels.taxInvoiceReferenceCode =
        this.header.TaxInvoiceReferenceRefText;
      this.formHeaderModels.taxInvoiceReferenceRefDate =
        this.header.TaxInvoiceReferenceRefDate;
    } else {
      this.formHeaderModels.taxInvoiceReference =
        this.taxInvoiceReferenceOptions.find(
          (item) => item.id === this.header.TaxInvoiceReferenceID
        );
    }

    this.formHeaderModels.postingDate = this.header.PostingDate;
    this.formHeaderModels.note = this.header.Notes;
    this.fileName = this.attachment.NameFile;
    this.attachments = this.attachment;
  },
  watch: {
    isSubmit() {
      if (!this.isSubmit) {
        return;
      }

      this.$emit("updateModel", this.formHeaderModels);
      if (this.file) {
        this.$emit("updateFile", this.file);
      }
    },
    settlementMethod() {
      this.formHeaderModels.settlementMethod = this.settlementMethod;
      console.log("settlementMethod", this.formHeaderModels.settlementMethod);
    },
    settlementMethodOptions() {
      this.formHeaderModels.settlementMethod =
        this.settlementMethodOptions.find(
          (item) => item.SettlementMethod === this.header.SettlementMethod
        );
    },
    header() {
      this.formHeaderModels.cnNumber = this.header.SupplierCNNumber;
      this.formHeaderModels.cnDate =
        moment(this.header.SupplierCNDate).format("YYYY-MM-DD") === "0001-01-01"
          ? null
          : this.header.SupplierCNDate;
      this.formHeaderModels.trNumber = this.header.TaxReturnNumber;

      this.formHeaderModels.trDate =
        moment(this.header.TaxReturnDate).format("YYYY-MM-DD") === "0001-01-01"
          ? null
          : this.header.TaxReturnDate;
      this.formHeaderModels.settlementMethod =
        this.settlementMethodOptions.find(
          (item) => item.SettlementMethod === this.header.SettlementMethod
        );

      if (this.header.TaxInvoiceReferenceRefText !== "") {
        this.formHeaderModels.taxInvoiceReferenceCode =
          this.header.TaxInvoiceReferenceRefText;
      } else {
        this.formHeaderModels.taxInvoiceReference =
          this.taxInvoiceReferenceOptions.find(
            (item) => item.id === this.header.TaxInvoiceReferenceID
          );
      }

      this.formHeaderModels.postingDate = this.header.PostingDate;
      this.formHeaderModels.note = this.header.Notes;
      this.fileName = this.attachment.NameFile;
      this.attachments = this.attachment;
    },
    taxInvoiceReferenceOptions() {
      this.formHeaderModels.taxInvoiceReference =
        this.taxInvoiceReferenceOptions.find(
          (item) => item.id === this.header.TaxInvoiceReferenceID
        );
    },
    "formHeaderModels.taxInvoiceReference"() {
      if (!this.isMounted) {
        this.isMounted = true;
        return;
      }
      if (this.formHeaderModels.taxInvoiceReference) {
        this.formHeaderModels.taxInvoiceReferenceCode = "";
        this.formHeaderModels.taxInvoiceReferenceRefDate =
          this.formHeaderModels.taxInvoiceReference.date;
      } else {
        this.formHeaderModels.taxInvoiceReferenceRefDate = null;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("dnReturn/setTax", {});
  },
};
</script>
