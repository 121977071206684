<template>
  <div class="flex flex-col gap-3">
    <!-- <h4>Header</h4> -->
    <div class="flex flex-col gap-4 w-full">
      <div class="flex flex-col gap-2 w-full sm:w-2/3">
        <!-- <vs-table :data="taxRateNames">
          <template slot="thead">
            <vs-th style="width: 3%"> No </vs-th>
            <vs-th style="width: 3%"> Tax Rate Name </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td> {{ indextr + 1 }} </vs-td>
              <vs-td>
                {{ tr }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table> -->
        <div class="flex flex-col gap-3">
          <span>Tax Rate</span>
          <multiselect
            v-model="selectedTaxRate"
            :options="taxOptions"
            placeholder="Type to search"
            label="Name"
            :allow-empty="true"
            :group-select="false"
            track-by="ID"
            @search-change="onTaxesChange"
            :max-height="125"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <span class="option__title">{{ props.option.Name }}</span>
                </span>
              </div>
            </template>
          </multiselect>
          <!-- <span>Tax Rate</span>
          <span>:</span>
          <span class="font-bold">{{ taxRateNames.join(", ") }}</span> -->
        </div>
      </div>
      <vs-table
        :data="tempLines"
        class="mt-6"
        v-if="this.formDetailModels.length > 0"
      >
        <template slot="thead">
          <vs-th> Action </vs-th>
          <vs-th> GRR Code </vs-th>
          <vs-th> Item Name </vs-th>
          <vs-th> Return Qty </vs-th>
          <vs-th> Unit </vs-th>
          <vs-th> Price </vs-th>
          <vs-th> Return Value </vs-th>
          <!-- <vs-th> Tax Rate </vs-th> -->
          <vs-th> Return Tax </vs-th>
          <vs-th> Return Total </vs-th>
          <!-- <vs-th> </vs-th> -->
          <vs-th> Qty </vs-th>
          <vs-th> Value </vs-th>
          <vs-th> Tax </vs-th>
          <vs-th> Total </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>
                <vx-tooltip
                  text="Remove"
                  style="width: fit-content"
                  v-if="tempLines.length > 1"
                >
                  <vs-button
                    @click="handleShowPrompt(tr, indextr)"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-trash"
                    type="line"
                  />
                </vx-tooltip>
                <div v-else>No action</div>
              </div>
            </vs-td>
            <vs-td>
              {{ tr.GrrCode }}
            </vs-td>
            <vs-td>
              {{ tr.ItemName }}
            </vs-td>
            <vs-td>
              {{ currentQuantities[indextr] }}
            </vs-td>
            <vs-td>
              {{
                findUnit(
                  tr.PurchaseReturnLineID
                    ? tr.PurchaseReturnLineID
                    : tr.SupplierReturnLineID
                )
              }}
            </vs-td>
            <vs-td>
              {{
                priceFormat(
                  findPrice(
                    tr.PurchaseReturnLineID
                      ? tr.PurchaseReturnLineID
                      : tr.SupplierReturnLineID
                  )
                )
              }}
            </vs-td>
            <vs-td>
              {{ priceFormat(currentValues[indextr]) }}
            </vs-td>
            <vs-td>
              {{ priceFormat(currentTaxes[indextr]) }}
            </vs-td>
            <!-- <vs-td>
              {{ `${supplierReturnDetails[indextr].tax_rate}%` }}
            </vs-td> -->
            <vs-td>
              {{ priceFormat(currentTotals[indextr]) }}
            </vs-td>
            <!-- <vs-td> </vs-td> -->
            <vs-td>
              <vs-input
                v-model="formDetailModels[indextr].qty"
                @keyup="
                  format(
                    'qty',
                    indextr,
                    tr.PurchaseReturnLineID
                      ? tr.PurchaseReturnLineID
                      : tr.SupplierReturnLineID
                  )
                "
              />
            </vs-td>
            <!-- <vs-td>
              <vs-input
                v-model="formDetailModels[indextr].value"
                @keyup="format('value', indextr)"
              />
            </vs-td> -->
            <vs-td>
              <vs-input
                v-model="formDetailModels[indextr].value"
                @keyup="
                  format(
                    'value',
                    indextr,
                    tr.PurchaseReturnLineID
                      ? tr.PurchaseReturnLineID
                      : tr.SupplierReturnLineID
                  )
                "
              />
            </vs-td>
            <vs-td>
              <vs-input readonly v-model="formDetailModels[indextr].tax" />
            </vs-td>
            <vs-td>
              <vs-input readonly v-model="formDetailModels[indextr].total" />
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td colspan="8"></vs-td>
            <vs-td>
              <span class="font-bold">Grand Total</span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.qty" />
              </span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.value" />
              </span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.tax" />
              </span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.total" />
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-divider v-if="tempDeletedLines.length > 0" />
      <div class="flex justify-end w-full" v-if="tempDeletedLines.length > 0">
        <div>
          <vs-button @click="addTempLine">Recover Item</vs-button>
        </div>
      </div>

      <vs-table
        :data="tempDeletedLines"
        class="mt-6"
        v-if="tempDeletedLines.length > 0"
      >
        <template slot="thead">
          <vs-th>
            <div class="flex justify-center w-full">
              <vs-checkbox>Check all</vs-checkbox>
            </div>
          </vs-th>
          <vs-th> GRR Code </vs-th>
          <vs-th> Item Name </vs-th>
          <vs-th> Return Qty </vs-th>
          <vs-th> Unit </vs-th>
          <vs-th> Price </vs-th>
          <vs-th> Return Value </vs-th>
          <!-- <vs-th> Tax Rate </vs-th> -->
          <vs-th> Return Tax </vs-th>
          <vs-th> Return Total </vs-th>
          <!-- <vs-th> </vs-th> -->
          <!-- <vs-th> Qty </vs-th>
          <vs-th> Value </vs-th>
          <vs-th> Tax </vs-th>
          <vs-th> Total </vs-th> -->
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              :class="
                getClass(
                  tr.PurchaseReturnLineID
                    ? tr.PurchaseReturnLineID
                    : tr.SupplierReturnLineID
                )
              "
            >
              <vs-checkbox
                @click="addCheckedLines(tr)"
                :checked="checked(tr.ID)"
              />
            </vs-td>
            <vs-td>
              {{ tr.GrrCode }}
            </vs-td>
            <vs-td>
              {{ tr.ItemName }}
            </vs-td>
            <vs-td>
              {{ tr.ReturnQuantity }}
            </vs-td>
            <vs-td>
              {{
                findUnit(
                  tr.PurchaseReturnLineID
                    ? tr.PurchaseReturnLineID
                    : tr.SupplierReturnLineID
                )
              }}
            </vs-td>
            <vs-td>
              {{
                priceFormat(
                  findPrice(
                    tr.PurchaseReturnLineID
                      ? tr.PurchaseReturnLineID
                      : tr.SupplierReturnLineID
                  )
                )
              }}
            </vs-td>
            <vs-td>
              {{ priceFormat(tr.ReturnValue) }}
            </vs-td>
            <vs-td>
              {{ priceFormat(tr.ReturnTax) }}
            </vs-td>
            <vs-td>
              {{ priceFormat(tr.ReturnTotalValue) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="handleClosePrompt"
        @accept="removeTempLine"
        @close="handleClosePrompt"
        :active.sync="isShowPrompt"
      >
        <div class="flex flex-col gap-3" color="danger">
          <span
            >Are you sure want to remove <br />
            <span class="font-bold">{{
              `${selectedLine.ItemName} ?`
            }}</span></span
          >
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    supplierReturnDetails: {
      type: Array,
      default: () => [],
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    lines: {
      type: Array,
      default: () => [],
    },
    tax: {
      type: Object,
      default: () => null,
    },
    deletedLines: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data() {
    return {
      taxRate: this.formatPrice(0),
      refAPInvoice: "",
      formDetailModels: [],
      isFirstMount: true,
      selectedLine: {},
      selectedLineIndex: -1,
      isShowPrompt: false,
      taxRateNames: [],
      partialTax: 0,
      partialTotal: 0,
      currentTaxes: [],
      currentTotals: [],
      currentValues: [],
      currentQuantities: [],
      selectedTaxRate: {},
      taxOptions: [],
      agrregate: {
        total: "",
        tax: "",
        value: "",
        qty: "",
      },
      tempLines: [],
      tempDeletedLines: [],
      checkedLines: [],
      checkedLinesID: [],
      unwatch: null,
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      (state) => state.dnReturn.taxInvoiceRefence,
      (val) => {
        this.selectedTaxRate = this.taxOptions.find((v) => v.ID === val.tax_id);
      }
    );
  },
  beforeDestroy() {
    if (this.unwatch) {
      this.unwatch();
    }
  },
  methods: {
    format(key, index, ref_id) {
      console.log("ref", ref_id);
      let data = this.formDetailModels[index][key];

      if (key === "qty") {
        this.formDetailModels[index][key] = this.formatQty(data);
      } else {
        this.formDetailModels[index][key] = this.formatPrice(data);
      }

      if (key === "qty") {
        const qty = parseInt(
          this.formDetailModels[index].qty
            ? this.formDetailModels[index].qty
            : 0
        );

        if (qty > 0) {
          const taxRate = this.selectedTaxRate.Rate;
          const srLine = this.supplierReturnDetails.find(
            (v) => v.id === ref_id
          );
          const price = parseFloat(srLine.price);
          // const maxQty = parseInt(this.supplierReturnDetails[index].qty);
          let value = qty * price;
          if (this.isFirstMount) {
            if (
              this.tempLines[index].DNValue !==
              this.tempLines[index].ReturnValue
            ) {
              value = this.tempLines[index].DNValue;
            }
            //for multiple line, we check the index of the last line
            if (index === this.tempLines.length - 1) {
              this.isFirstMount = false;
            }
          }

          this.formDetailModels[index].value = this.priceFormat(value);

          // let tax =
          //   (qty / parseFloat(this.supplierReturnDetails[index].qty)) *
          //   parseFloat(this.supplierReturnDetails[index].tax);
          const tax = Math.floor((taxRate / 100) * value);

          this.formDetailModels[index].tax = this.priceFormat(tax);

          const valueTemp = parseFloat(
            value.toString().replace(/[^0-9.]/g, "")
          );
          const total = (valueTemp + tax).toFixed(2);
          this.formDetailModels[index].total = this.priceFormat(total);
        } else {
          this.formDetailModels[index].value = this.priceFormat(0);
          this.formDetailModels[index].total = this.priceFormat(0);
          this.formDetailModels[index].tax = this.priceFormat(0);
        }
      } else if (key === "value") {
        const value = parseFloat(
          this.formDetailModels[index].value
            ? this.formDetailModels[index].value.replace(/[^0-9.]/g, "")
            : 0
        );

        const taxRate = this.selectedTaxRate.Rate;
        const tax = Math.floor((parseFloat(taxRate) / 100) * value);

        this.formDetailModels[index].tax = this.priceFormat(tax);

        const total = (value + tax).toFixed(2);
        this.formDetailModels[index].total = this.priceFormat(total);
      }

      this.calculateAggregate();
    },
    formatTax() {
      let data = this.taxRate;
      this.taxRate = this.formatPrice(data);
    },
    formatPrice(angka) {
      return angka
        .toString()
        .replace(/[^.\d]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatQty(angka) {
      return angka
        .toString()
        .replace(/[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onQtyChange(qty, index) {
      console.log("qty", qty);
      const maxQty = this.supplierReturnDetails[index].qty;
      const qtyTemp = parseInt(qty.replace(/[^0-9]/g, ""));
      if (qtyTemp > maxQty) {
        this.formDetailModels[index].qty = this.priceFormat(maxQty);
      } else {
        this.formDetailModels[index].qty = this.priceFormat(qtyTemp);
      }
    },
    handleShowPrompt(selected, index) {
      this.isShowPrompt = true;
      this.selectedLine = selected;
      this.selectedLineIndex = index;
      console.log(this.selectedLine);
    },
    handleClosePrompt() {
      this.isShowPrompt = false;
      this.selectedLineIndex = -1;
      this.selectedLine = {};
    },
    removeLine() {
      this.$vs.loading();
      const params = {
        reopen_qty: this.selectedLine.DNQuantity,
      };
      if (this.selectedLine.PurchaseOrderLineID > 0) {
        params.purchase_order_line_id = this.selectedLine.PurchaseOrderLineID;
        params.purchase_return_line_id = this.selectedLine.PurchaseReturnLineID;
      } else if (this.selectedLine.SupplierReturnLineID > 0) {
        params.supplier_return_line_id = this.selectedLine.SupplierReturnLineID;
      }

      this.$http
        .delete("/api/v1/debit-note/return/line/" + this.selectedLine.ID, {
          params,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Remove item",
              text: "Item has been removed",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$emit("reload");
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    findPrice(id) {
      const line = this.supplierReturnDetails.find((v) => v.id === id);
      return line.price;
    },
    findUnit(id) {
      const line = this.supplierReturnDetails.find((v) => v.id === id);
      return line.unit;
    },
    getClass(id) {
      const line = this.supplierReturnDetails.find((v) => v.id === id);
      return line.remaining_qty === 0 ? "bg-dark" : "bg-white";
    },
    isDissabled(id) {
      const line = this.supplierReturnDetails.find((v) => v.id === id);
      return line.remaining_qty === 0;
    },
    removeTempLine() {
      this.tempDeletedLines.push(this.selectedLine);
      this.tempLines.splice(this.selectedLineIndex, 1);
      this.formDetailModels.splice(this.selectedLineIndex, 1);
      this.currentQuantities.splice(this.selectedLineIndex, 1);
      this.currentValues.splice(this.selectedLineIndex, 1);
      this.currentTaxes.splice(this.selectedLineIndex, 1);
      this.currentTotals.splice(this.selectedLineIndex, 1);
      console.log(this.formDetailModels);
      this.handleClosePrompt();
      this.calculateAggregate();
    },
    addTempLine() {
      if (this.checkedLinesID.length === 0 || this.checkedLines.length === 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select item to add",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-square",
        });
        return;
      }

      // const tempFormDetail = [];
      this.checkedLines.forEach((line) => {
        const srLineID = line.PurchaseReturnLineID
          ? line.PurchaseReturnLineID
          : line.SupplierReturnLineID;
        const srLine = this.supplierReturnDetails.find(
          (v) => v.id === srLineID
        );
        let qty = srLine.remaining_qty + line.DNQuantity;
        if (qty > srLine.qty) {
          qty = srLine.qty;
        }

        const currentValue = parseInt(qty) * parseFloat(srLine.price);
        // const currentTax =
        //   (parseFloat(this.selectedTaxRate.Rate) / 100) *
        //   parseFloat(currentValue);
        const srTotal = currentValue + Math.floor(srLine.tax);
        // const currentTotal = currentValue + currentTax;

        const temp = {
          id: line.ID,
          qty: this.formatQty(line.DNQuantity),
          value: this.priceFormat(line.DNValue),
          tax: this.priceFormat(line.DNTax),
          total: this.priceFormat(line.DNTotalValue),
          remaining_qty: this.formatQty(srLine.remaining_qty),
          max_quantity: this.formatQty(qty),
          grr_code: line.GrrCode,
          item_name: line.ItemName,
          is_recover: this.deletedLines.some((v) => v.ID === line.ID),
        };

        if (line.PurchaseOrderLineID) {
          temp.ref_id = line.PurchaseOrderLineID;
          temp.prl_id = line.PurchaseReturnLineID;
        } else if (line.SupplierReturnLineID) {
          temp.ref_id = line.SupplierReturnLineID;
        }

        // total += parseFloat(currentTotal);
        // tax += parseFloat(currentTax);
        // value += parseFloat(currentValue);
        // aggregateQty += parseInt(qty);

        this.currentTaxes.push(Math.floor(srLine.tax));
        this.currentTotals.push(srTotal);
        this.currentValues.push(currentValue);
        this.currentQuantities.push(qty);
        this.formDetailModels.push(temp);
        // tempFormDetail.push(temp);
        // this.formDetailModels = [...tempFormDetail];
      });

      this.checkedLines.forEach((line) => {
        this.tempDeletedLines.splice(
          this.tempDeletedLines.findIndex((d) => d.ID === line.ID),
          1
        );
      });

      this.tempLines.push(...this.checkedLines);
      // this.tempDeletedLines.splice(this.selectedLineIndex, 1);
      this.calculateAggregate();
      this.handleClosePrompt();
      this.checkedLines = [];
      this.checkedLinesID = [];
    },
    addCheckedLines(line) {
      if (!this.checkedLinesID.includes(line.ID)) {
        this.checkedLines.push(line);
        this.checkedLinesID.push(line.ID);
      } else {
        const index = this.checkedLines.findIndex((v) => v.ID === line.ID);
        this.checkedLines.splice(index, 1);
        this.checkedLinesID.splice(index, 1);
      }
      console.log(this.checkedLinesID);
      console.log(this.checkedLines);
    },
    checked(id) {
      return this.checkedLinesID.includes(id);
    },
    onTaxesChange(search) {
      if (search.length < 3 && search.length > 0) {
        return;
      }
    },
    async getTaxOptions(search) {
      try {
        const resp = await this.$http.get("/api/v1/master/tax", {
          params: {
            search,
            order: "id",
            sort: "desc",
            length: 20,
          },
        });
        if (resp.code < 299) {
          this.taxOptions = resp.data.records;
          console.log("option", this.taxOptions);
          console.log("supplier", this.supplierReturnDetails[0].tax_name);

          if (this.tax.Name) {
            this.selectedTaxRate = this.taxOptions.find(
              (v) => v.Name === this.tax.Name
            );
          } else {
            this.selectedTaxRate = this.taxOptions.find(
              (v) => v.Name === this.supplierReturnDetails[0].tax_name
            );
          }

          console.log("selected", this.selectedTaxRate);
        } else {
          this.taxOptions = [];
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-square",
          });
        }
        this.taxOptions = resp.data.records;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    calculateAggregate() {
      let aggregateTotal = 0;
      let aggregateTax = 0;
      let aggregateValue = 0;
      let aggregateQty = 0;

      this.formDetailModels.forEach((line) => {
        const qty = parseInt(line.qty.replace(/[^0-9]/g, ""));
        const value = parseFloat(line.value.replace(/[^0-9.]/g, ""));
        const tax = parseFloat(line.tax.replace(/[^0-9.]/g, ""));
        const total = parseFloat(line.total.replace(/[^0-9.]/g, ""));

        aggregateTotal += total;
        aggregateTax += tax;
        aggregateValue += value;
        aggregateQty += qty;
      });

      this.agrregate.total = this.priceFormat(aggregateTotal);
      this.agrregate.tax = this.priceFormat(aggregateTax);
      this.agrregate.value = this.priceFormat(aggregateValue);
      this.agrregate.qty = this.formatQty(aggregateQty);
    },
  },

  async mounted() {
    console.log("deleted lines", this.deletedLines);
    await this.getTaxOptions("B");
    this.isShowPrompt = false;
    let total = 0;
    let tax = 0;
    let value = 0;
    let aggregateQty = 0;
    this.tempLines = [...this.lines];
    this.tempDeletedLines = [...this.deletedLines];
    this.formDetailModels = this.tempLines.map((line) => {
      const srLineID = line.PurchaseReturnLineID
        ? line.PurchaseReturnLineID
        : line.SupplierReturnLineID;
      const srLine = this.supplierReturnDetails.find((v) => v.id === srLineID);

      let qty = srLine.remaining_qty + line.DNQuantity;
      if (qty > srLine.qty) {
        qty = srLine.qty;
      }

      // let currentValue = 0;

      // if (line.DNValue !== line.ReturnValue) {
      //   currentValue
      // } else {
      //   currentValue = parseInt(qty) * parseFloat(srLine.price);
      // }

      const currentValue = parseInt(qty) * parseFloat(srLine.price);
      const currentTax = Math.floor(
        (parseFloat(this.selectedTaxRate.Rate) / 100) * parseFloat(currentValue)
      );

      const srTotal = currentValue + Math.floor(srLine.tax);
      const currentTotal = currentValue + currentTax;

      const temp = {
        id: line.ID,
        qty: this.formatQty(line.DNQuantity),
        value: this.priceFormat(line.DNValue),
        tax: this.priceFormat(line.DNTax),
        total: this.priceFormat(line.DNTotalValue),
        remaining_qty: this.formatQty(srLine.remaining_qty),
        max_quantity: this.formatQty(qty),
        grr_code: line.GrrCode,
        item_name: line.ItemName,
      };

      console.log("temp", JSON.stringify(temp));

      if (line.PurchaseOrderLineID) {
        temp.ref_id = line.PurchaseOrderLineID;
        temp.prl_id = srLineID;
      } else if (line.SupplierReturnLineID) {
        temp.ref_id = line.SupplierReturnLineID;
      }

      total += parseFloat(currentTotal);
      tax += parseFloat(currentTax);
      value += parseFloat(currentValue);
      aggregateQty += parseInt(qty);

      this.currentTaxes.push(Math.floor(srLine.tax));
      this.currentTotals.push(srTotal);
      this.currentValues.push(currentValue);
      this.currentQuantities.push(qty);
      // tempTaxName.push(this.supplierReturnDetails[index].tax_name);
      return temp;
    });

    console.log("formDetailModels", this.formDetailModels);
    this.agrregate.total = this.priceFormat(total);
    this.agrregate.tax = this.priceFormat(tax);
    this.agrregate.value = this.priceFormat(value);
    this.agrregate.qty = this.formatQty(aggregateQty);
  },
  watch: {
    isSubmit() {
      if (this.isSubmit) {
        const temp = {
          taxRate: this.selectedTaxRate.Rate,
          refAPInvoice: this.refAPInvoice,
          taxID: this.selectedTaxRate.ID,
          details: this.formDetailModels,
          deleted: this.tempDeletedLines.filter(
            (v) => !this.deletedLines.some((d) => d.ID === v.ID)
          ),
        };
        this.$emit("updateModel", temp);
        // this.$emit("resetIsSubmit");
      }
    },
    selectedTaxRate() {
      this.$nextTick(() => {
        for (let index = 0; index < this.formDetailModels.length; index++) {
          this.format(
            "qty",
            index,
            this.formDetailModels[index].prl_id
              ? this.formDetailModels[index].prl_id
              : this.formDetailModels[index].ref_id
          );
        }
      });
    },
    deletedLines() {
      this.tempDeletedLines = [...this.deletedLines];
    },
    lines() {
      this.tempLines = [...this.lines];
    },
  },
};
</script>
